<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Joe Font", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, "sans-serif";
}
html{
  /* overflow-x: hidden; */
}
.wrap {
  width: 1200px;
}
ul{
  list-style-type: none;
}
a{
  text-decoration: none;
  color: #333;
}

html::-webkit-scrollbar {
  width: 8px; 
}
 
html::-webkit-scrollbar-track {
  background-color: 0 0;
}
 
html::-webkit-scrollbar-thumb {
  background: #c0c4cc;
  border-radius: 4px;
}
</style>
